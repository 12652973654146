import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Box, Typography, Button } from '@mui/material';
import NextQuestion from './NextQuestion';
import { postAnswer } from '../../../../clients/rotr-client';
import { UUID } from 'crypto';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import RuleDisplay from './RuleDisplay';

// List of maritime-themed emojis
const maritimeEmojis = [
  '🚢',
  '⚓',
  '🌊',
  '🐠',
  '🐳',
  '🦈',
  '🐙',
  '🦀',
  '🐚',
  '🏖️',
  '🏝️',
  '🛥️',
  '⛵',
  '🎣',
  '🗺️',
  '🧭',
  '🌴'
];

interface RenderReadQuestionProps {
  attemptId: UUID;
  questionId: UUID;
  currentQuestion: any;
  currentQuestionIndex: number;
  submitted: boolean;
  setSubmitted: (submitted: boolean) => void;
  handleNextQuestion: () => void;
  updateScore: (score: number) => void;
  setTimerRunning: (running: boolean) => void;
}
const RenderReadQuestion: React.FC<RenderReadQuestionProps> = ({
  attemptId,
  questionId,
  currentQuestion,
  currentQuestionIndex,
  submitted,
  setSubmitted,
  handleNextQuestion,
  updateScore,
  setTimerRunning
}) => {
  const [foundIcons, setFoundIcons] = useState<string[]>([]);
  const [isCorrect, setIsCorrect] = useState<boolean>(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [animatingIcon, setAnimatingIcon] = useState<string | null>(null);
  const [clickedIcon, setClickedIcon] = useState<string | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [iconPositions, setIconPositions] = useState<{ [key: string]: number }>(
    {}
  );
  const [contentWords, setContentWords] = useState<string[]>([]);
  const [visibleIcons, setVisibleIcons] = useState<string[]>([]);

  const { content, emojiMap } = useMemo(() => {
    const newEmojiMap: { [key: string]: string } = {};
    const numIcons = currentQuestion.options.length;

    for (let i = 0; i < numIcons; i++) {
      const iconId = `icon-${i}`;
      newEmojiMap[iconId] =
        maritimeEmojis[Math.floor(Math.random() * maritimeEmojis.length)];
    }

    return { content: currentQuestion.content, emojiMap: newEmojiMap };
  }, [currentQuestion.content, currentQuestion.options]);

  useEffect(() => {
    // Reset states for new question
    setSubmitted(false);
    setFoundIcons([]);
    setIsCorrect(false);
    setShowNextButton(false);
    setTimerRunning(true);

    // Generate icon positions and content words
    if (content && currentQuestion.options.length > 0) {
      // Create a temporary div to parse HTML content
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = content;
      const textContent = tempDiv.textContent || tempDiv.innerText;
      const words = textContent.split(/\s+/).filter((w) => w.length > 0);

      const newIconPositions: { [key: string]: number } = {};
      const numIcons = currentQuestion.options.length;

      // Simple distribution: divide text into numIcons+1 segments
      const segmentSize = Math.max(
        1,
        Math.floor(words.length / (numIcons + 1))
      );

      // Place icons at regular intervals, avoiding start and end
      for (let i = 0; i < numIcons; i++) {
        const position = Math.min(segmentSize * (i + 1), words.length - 1);
        newIconPositions[`icon-${i}`] = position;
      }

      console.log('Number of icons to display:', numIcons);
      console.log('Icon positions:', newIconPositions);

      setIconPositions(newIconPositions);
      setContentWords(words);
    }
  }, [currentQuestion, content, setSubmitted, setTimerRunning]);

  const renderContent = useMemo(() => {
    if (!content) return null;

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;

    // Function to get text nodes recursively, excluding style tags
    const getTextNodes = (node: Node): Text[] => {
      const textNodes: Text[] = [];
      const walker = document.createTreeWalker(node, NodeFilter.SHOW_TEXT, {
        acceptNode: (node) => {
          if (node.parentElement?.tagName === 'STYLE') {
            return NodeFilter.FILTER_REJECT;
          }
          return node.textContent?.trim()
            ? NodeFilter.FILTER_ACCEPT
            : NodeFilter.FILTER_REJECT;
        }
      } as NodeFilter);

      let currentNode: Node | null;
      while ((currentNode = walker.nextNode())) {
        textNodes.push(currentNode as Text);
      }
      return textNodes;
    };

    // Get all text nodes and their word counts
    const textNodes = getTextNodes(tempDiv);
    let totalWordCount = 0;
    const nodeInfo = textNodes.map((node) => {
      const words =
        node.textContent?.split(/\s+/).filter((w) => w.length > 0) || [];
      const info = {
        node,
        words,
        startIndex: totalWordCount,
        endIndex: totalWordCount + words.length,
        iconsToInsert: [] as { position: number; iconId: string }[]
      };
      totalWordCount += words.length;
      return info;
    });

    // Sort icon positions and assign them to the appropriate nodes
    const sortedPositions = Object.entries(iconPositions).sort(
      ([, a], [, b]) => a - b
    );

    // First, assign all icons to their target nodes
    sortedPositions.forEach(([iconId, targetPosition]) => {
      const targetNode = nodeInfo.find(
        (info) =>
          targetPosition >= info.startIndex && targetPosition < info.endIndex
      );
      if (targetNode) {
        targetNode.iconsToInsert.push({
          position: targetPosition - targetNode.startIndex,
          iconId
        });
      }
    });

    // Then process each node and insert all its assigned icons at once
    nodeInfo.forEach((info) => {
      if (info.iconsToInsert.length > 0) {
        // Sort icons by position in reverse order (to insert from end to start)
        info.iconsToInsert.sort((a, b) => b.position - a.position);

        let words = [...info.words];

        // Insert all icons for this node
        info.iconsToInsert.forEach(({ position, iconId }) => {
          const iconButton = `<button 
            class="icon-button" 
            data-icon-id="${iconId}" 
            style="background: none; border: none; cursor: pointer; font-size: 1.2em; padding: 0 4px; opacity: ${
              foundIcons.includes(iconId) ? '0' : '1'
            }; transition: opacity 0.3s, transform 0.3s; transform: ${
            clickedIcon === iconId ? 'scale(1.5)' : 'scale(1)'
          }; text-shadow: 0 0 3px rgba(255,255,255,0.8);"
          >${emojiMap[iconId]}</button>`;

          // Insert the icon at the specified position
          words.splice(position, 0, iconButton);
        });

        // Create a new span with all icons inserted
        const span = document.createElement('span');
        span.innerHTML = words.join(' ');
        info.node.parentNode?.replaceChild(span, info.node);
      }
    });

    return (
      <div
        dangerouslySetInnerHTML={{ __html: tempDiv.innerHTML }}
        onClick={(e) => {
          const button = (e.target as HTMLElement).closest('.icon-button');
          if (button) {
            const iconId = button.getAttribute('data-icon-id');
            if (iconId) {
              handleIconClick(iconId);
            }
          }
        }}
      />
    );
  }, [content, iconPositions, foundIcons, emojiMap, clickedIcon]);

  const handleIconClick = (iconId: string) => {
    if (!foundIcons.includes(iconId)) {
      setClickedIcon(iconId);
      setTimeout(() => {
        setFoundIcons((prev) => [...prev, iconId]);
        setClickedIcon(null);
      }, 500);
    }
  };

  const handleSubmit = async () => {
    if (submitted) return; // Prevent duplicate submissions
    setSubmitted(true);

    const answers = foundIcons.length;

    try {
      setTimerRunning(false);
      const APIResponse = await postAnswer(attemptId, questionId, answers);
      // Response is correct if the APIResponse.responseAnswers is equal to the APIResponse.score
      const isCorrect = APIResponse.responseAnswers === APIResponse.score;

      setIsCorrect(isCorrect);

      setShowNextButton(true);
      updateScore(APIResponse.score);
    } catch (error) {
      console.error('Error submitting answer:', error);
      setSubmitted(false);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        margin: '4px 0',
        padding: '0 12px',
        textAlign: 'left',
        color: 'white',
        minHeight: '200px',
        lineHeight: '2.5',
        position: 'relative'
      }}
    >
      {/* Instructions box */}
      <Box
        sx={{
          backgroundColor: submitted
            ? isCorrect
              ? 'green'
              : 'purple'
            : 'purple',
          padding: '20px',
          borderRadius: '10px',
          marginBottom: '10px'
        }}
      >
        <Typography variant='h6' sx={{ fontSize: '1.0rem' }}>
          <strong>{currentQuestion.instruction}</strong>
          <RuleDisplay
            inputRules={currentQuestion.rules}
            showRuleLink={false}
          />
          {submitted && isCorrect && (
            <Typography
              variant='h4'
              sx={{ color: 'white', marginTop: '10px', fontWeight: 'bold' }}
            >
              All Items Found! <CheckCircleTwoToneIcon />
            </Typography>
          )}
        </Typography>
      </Box>

      {/* Content box with Collected Items */}
      <Box
        className='content'
        ref={contentRef}
        sx={{
          marginTop: '20px',
          color: 'white',
          position: 'relative',
          border: '1px solid white',
          padding: '20px',
          paddingTop: '40px', // Increased top padding for collected items
          lineHeight: '1.8',
          fontSize: '1.1rem',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          borderRadius: '8px',
          boxShadow: '0 0 10px rgba(255, 255, 255, 0.1)',
          maxWidth: '800px',
          margin: '20px auto'
        }}
      >
        {/* Collected Items */}
        <Box
          sx={{
            position: 'absolute',
            top: '5px',
            left: '5px',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '5px',
            maxWidth: 'calc(100% - 10px)'
          }}
        >
          <Typography
            variant='caption'
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              marginRight: '5px',
              alignSelf: 'center'
            }}
          >
            Collected:
          </Typography>
          {foundIcons.map((iconId, index) => (
            <div
              key={iconId}
              className='found-icon'
              style={{
                opacity: 0,
                transform: 'scale(0)',
                animation: 'appear 0.3s forwards',
                animationDelay: `${index * 0.1}s`,
                fontSize: '1.2em',
                width: '24px',
                height: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '4px'
              }}
            >
              {emojiMap[iconId]}
            </div>
          ))}
        </Box>

        {/* Content */}
        {renderContent}
      </Box>

      <Box display='flex' justifyContent='center' marginTop='20px'>
        {showNextButton && <NextQuestion onClick={handleNextQuestion} />}
        {!submitted && (
          <Button
            variant='contained'
            sx={{
              backgroundColor: 'purple',
              color: 'white',
              padding: '10px 20px',
              fontSize: '1rem',
              textTransform: 'none',
              width: '150px',
              '&:hover': { backgroundColor: '#800080' }
            }}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default RenderReadQuestion;
